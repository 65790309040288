<template>
  <div class="seckill_wrapper" v-loading="loading">
    <el-card class="seckill_setting">
      <el-steps :active="activeIndex" align-center>
        <el-step title="创建活动"> </el-step>
        <el-step title="添加商品"></el-step>
      </el-steps>
      <div style="margin-top: 20px">
        <el-form
          ref="form"
          class="lotterySetting"
          :rules="rules"
          :model="form"
          label-width="200px"
          v-show="activeIndex === 0"
        >
          <el-form-item label="活动名称" prop="name">
            <el-input v-model="form.name" class="com-input" placeholder="请输入活动名称" :disabled="isView" />
          </el-form-item>
          <el-form-item label="活动时间" prop="dateRange">
            <el-date-picker
              v-model="form.dateRange"
              type="datetimerange"
              :default-time="['00:00:00', '23:59:59']"
              value-format="timestamp"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="activityTimeOptions"
              :disabled="isView"
            />
          </el-form-item>
          <el-form-item label="活动说明" prop="description">
            <el-input
              type="textarea"
              class="com-input"
              :rows="2"
              placeholder="请输入内容"
              v-model="form.description"
              :disabled="isView"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div v-show="activeIndex === 1">
          <el-button type="primary" style="margin-bottom: 20px" @click="handleAdd" :disabled="isView"
            >添加商品</el-button
          >
          <el-table :data="tableData" style="width: 100%" :span-method="mergeRow" ref="multipleTable">
            <el-table-column align="center" label="商品图片">
              <template slot-scope="{ row }">
                <div class="wrapper">
                  <img class="avatar" :src="row.productImageUrl | thumbSmall" />
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="productName" label="商品名称"> </el-table-column>
            <!-- <el-table-column align="center" prop="productName" label="商品类型"> </el-table-column> -->
            <el-table-column align="center" prop="productId" label="商品编号"> </el-table-column>
            <el-table-column align="center" label="排序" width="140">
              <template slot-scope="{ row }">
                <el-input-number
                  v-model="row.secKillSortNo"
                  @change="handleSetSort($event, row.productId)"
                  :min="0"
                  :controls="false"
                  label="分销金"
                  v-show="currentEditId.includes(row.productId)"
                ></el-input-number>
                <p v-show="!currentEditId.includes(row.productId)">{{ row.secKillSortNo }}</p>
              </template>
            </el-table-column>
            <el-table-column align="center" label="sku名称">
              <template slot-scope="{ row }">
                <p>{{ row.skuData && row.skuData.productSkuName }}</p>
              </template>
            </el-table-column>
            <el-table-column align="center" label="售价">
              <template slot-scope="{ row }">
                <p>{{ row.skuData && row.skuData.salePrice }}</p>
              </template>
            </el-table-column>
            <el-table-column align="center" label="剩余库存">
              <template slot-scope="{ row }">
                <p>{{ row.skuData && row.skuData.skuInventory }}</p>
              </template>
            </el-table-column>
            <el-table-column align="center" label="秒杀价" width="140">
              <template slot-scope="{ row, $index }">
                <el-input-number
                  v-model="row.skuData.secKillPrice"
                  @change="handleSetProperty($event, $index, 'secKillPrice')"
                  :min="0"
                  :controls="false"
                  label="秒杀价"
                  v-show="currentEditId.includes(row.productId)"
                ></el-input-number>
                <p v-show="!currentEditId.includes(row.productId)">{{ row.skuData.secKillPrice }}</p>
              </template>
            </el-table-column>
            <el-table-column align="center" label="秒杀数量" width="140">
              <template slot-scope="{ row, $index }">
                <el-input-number
                  v-model="row.skuData.secKillStock"
                  @change="handleSetProperty($event, $index, 'secKillStock')"
                  :min="0"
                  :controls="false"
                  label="秒杀数量"
                  v-show="currentEditId.includes(row.productId)"
                ></el-input-number>
                <p v-show="!currentEditId.includes(row.productId)">{{ row.skuData.secKillStock }}</p>
              </template>
            </el-table-column>
            <el-table-column align="center" label="已售量">
              <template slot-scope="{ row }">
                <p>{{ row.skuData && row.skuData.secKillSaleCount }}</p>
              </template>
            </el-table-column>
            <el-table-column align="center" label="每人限购" width="140">
              <template slot-scope="{ row, $index }">
                <el-input-number
                  v-model="row.skuData.buyLimit"
                  @change="handleSetProperty($event, $index, 'buyLimit')"
                  :min="0"
                  :controls="false"
                  label="每人限购"
                  v-show="currentEditId.includes(row.productId)"
                ></el-input-number>
                <p v-show="!currentEditId.includes(row.productId)">{{ row.skuData.buyLimit }}</p>
              </template>
            </el-table-column>
            <el-table-column align="center" label="是否分销">
              <template slot-scope="{ row }">
                <el-switch
                  v-model="row.distributionStatus"
                  :active-value="1"
                  :inactive-value="0"
                  :disabled="!currentEditId.includes(row.productId)"
                  @change="handleSetFen($event, row.productId)"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column align="center" label="分销金" width="140">
              <template slot-scope="{ row, $index }">
                <template v-if="+row.distributionStatus === 1">
                  <el-input-number
                    v-model="row.skuData.secKillDistributionRewardAmount"
                    @change="handleSetProperty($event, $index, 'secKillDistributionRewardAmount')"
                    :min="0"
                    :controls="false"
                    label="分销金"
                    v-show="currentEditId.includes(row.productId)"
                  ></el-input-number>
                  <p v-show="!currentEditId.includes(row.productId)">
                    {{ row.skuData.secKillDistributionRewardAmount }}
                  </p>
                </template>
                <template v-else>--</template>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" v-if="!isView">
              <template slot-scope="{ row }">
                <el-button
                  type="text"
                  size="small"
                  @click="handleEdit(row.productId)"
                  v-show="!currentEditId.includes(row.productId)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="handleSave(row)"
                  v-show="currentEditId.includes(row.productId)"
                  >保存</el-button
                >
                <el-button style="color: red" type="text" size="small" @click="handleDel(row.productId)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div style="margin-left: 130px; margin-top: 20px">
        <el-button type="primary" @click="handleStep(activeIndex)">{{
          activeIndex === 0 ? '下一步' : '完成'
        }}</el-button>
      </div>
    </el-card>
    <add-form
      ref="addForm"
      :is-edit="isEdit"
      :activity-id="activityId"
      :selected-product-id="selectedProductId"
      @getData="handleGetData"
    />
  </div>
</template>

<script>
import AddForm from './add'

export default {
  components: {
    AddForm
  },
  data() {
    return {
      isNormal: false,
      rules: {
        name: [
          {
            required: true,
            message: '请输入活动名称',
            trigger: 'blur'
          }
        ],
        dateRange: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (!value.length) {
                return callback(new Error('选择活动时间'))
              } else {
                callback()
              }
            }
          }
        ]
      },
      form: {
        name: '',
        dateRange: [],
        description: ''
      },
      originData: [],
      originEditData: [], //接口的编辑数据
      tableData: [],
      activityTimeOptions: {
        disabledDate(date) {
          return date && date.valueOf() < Date.now() - 86400000
        }
      },
      currentEditId: [],
      currentPage: 1,
      pageSizeOpts: [10, 20, 50, 100],
      pageSize: 10,
      pageTotal: 0,
      layoutConfig: 'total, sizes, prev, pager, next, jumper',
      loading: false,
      limitedTimeSaleActivityId: 0,
      activeIndex: 0
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isNormal) {
      next()
    } else {
      this.$confirm('如果需要离开当前页面，请保存后再离开', '提示', {
        confirmButtonText: '我知道了',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          next()
        })
        .catch(() => {
          window.history.go(1)
        })
    }
  },
  computed: {
    activityId() {
      return this.$route.query.id || 0
    },
    isEdit() {
      return this.$route.query.type === 'edit'
    },
    isView() {
      return this.$route.query.type === 'view'
    },
    selectedProductId() {
      return this.tableData.map(c => c.productId)
    }
  },

  methods: {
    handleEdit(id) {
      this.currentEditId.push(id)
    },
    handleSetProperty(val, index, key) {
      this.tableData.forEach((item, i) => {
        if (i === index) {
          item.skuData[key] = val || 0
        }
      })
    },
    handleSetSort(val, id) {
      this.tableData.forEach(c => {
        if (c.productId === id) {
          c.secKillSortNo = val
        }
      })
    },
    handleSetFen(val, id) {
      this.tableData.forEach(c => {
        if (c.productId === id) {
          c.distributionStatus = val
        }
      })
    },
    loadProductData() {
      this.loading = true
      return this.$api.seckill
        .seckill(
          'getSecKillRelation',
          {
            params: {
              limitedTimeSaleActivityId: this.activityId,
              page: 1,
              size: 1000
            },
            headers: {
              ['Bm-Area-Id']: this.$store.state.seckill.currentId
            }
          },
          this.activityId
        )
        .then(res => {
          this.tableData = this.transformData(res)
          this.originEditData = JSON.parse(JSON.stringify(this.transformData(res)))
        })
        .finally(_ => {
          this.loading = false
        })
    },
    loadData() {
      this.loading = true
      return this.$api.seckill
        .seckill('getSaleActivity', this.activityId, {
          headers: {
            ['Bm-Area-Id']: this.$store.state.seckill.currentId
          }
        })
        .then(res => {
          let { startTime, endTime } = res
          this.form = {
            ...res,
            dateRange: [startTime, endTime]
          }
        })
        .finally(_ => {
          this.loading = false
        })
    },
    handleChangePage(val) {
      this.currentPage = val
    },
    handlePageSize(val) {
      this.pageSize = val
    },
    transformData(data) {
      let transData = []
      data.forEach(item => {
        let d = item.secKillProductSkuAddVOList || item.productSkuLimitedSecKillRelationVOList
        let len = d.length
        d.forEach((c, index) => {
          transData.push({
            distributionStatus: 0,
            secKillSortNo: 0,
            ...item,
            productSkuLimitedSecKillRelationDBOS: [],
            skuData: {
              secKillPrice: 0,
              secKillStock: 0,
              buyLimit: 0,
              secKillDistributionRewardAmount: 0,
              secKillSaleCount: 0,
              ...c
            },
            rowMergeInfo: index === 0 ? [len, 1] : [0, 0]
          })
        })
      })
      return transData
    },
    mergeRow({ row, column, rowIndex, columnIndex }) {
      let skuIndex = [0, 1, 2, 3, 11, 13]
      if (skuIndex.includes(columnIndex)) {
        return row.rowMergeInfo
      } else {
        return [1, 1]
      }
    },
    handleGetData(val) {
      let filterData = val.filter(c => !this.selectedProductId.includes(c.productId))
      if (filterData.length) {
        let data = this.transformData(filterData)
        this.tableData = this.tableData.concat(...data)
        // this.pageTotal = val.length
        this.originData = JSON.parse(JSON.stringify(this.tableData))
      }
    },
    handleAdd() {
      this.$refs.addForm.toggleModal()
      this.$refs.addForm.loadData()
    },
    handleDel(id) {
      for (let i = this.tableData.length - 1; i >= 0; i--) {
        if (id === this.tableData[i].productId) {
          this.tableData.splice(i, 1)
        }
      }
    },
    handleData(data) {
      let result = []
      let temp = {}
      data.forEach(c => {
        if (!(c.productId in temp)) {
          c.productSkuLimitedSecKillRelationDBOS = [c.skuData]
          result.push(c)
          temp[c.productId] = true
        } else {
          let index = result.findIndex(s => s.productId === c.productId)
          result[index].productSkuLimitedSecKillRelationDBOS.push(c.skuData)
        }
      })
      // 字段补齐
      result.forEach(c => {
        c.limitedTimeSaleActivityId = this.limitedTimeSaleActivityId
        c.deleteStatus = 0
      })
      return result
    },
    handleStep(step) {
      if (step === 0) {
        // 下一步
        this.hanleCheck()
      } else {
        // 保存
        this.loading = true
        return this.$api.seckill
          .seckill('saveProductCount', this.limitedTimeSaleActivityId, {
            headers: {
              ['Bm-Area-Id']: this.$store.state.seckill.currentId
            }
          })
          .then(_ => {
            this.isNormal = true
            this.$message.success('操作成功')
            this.$router.push('/seckill/list')
          })
          .catch(err => {
            this.$message.error(err.msg || '操作失败')
          })
          .finally(_ => {
            this.loading = false
          })
      }
    },
    hanleCheck() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          let params = Object.assign(
            {
              description: this.form.description,
              limitedTimeSaleProductCount: 0,
              name: this.form.name,
              startTime: this.form.dateRange[0],
              endTime: this.form.dateRange[1]
            },
            this.activityId
              ? {
                  id: this.activityId
                }
              : {}
          )
          return this.$api.seckill
            .seckill('addSaleActivity', params, {
              headers: {
                ['Bm-Area-Id']: this.$store.state.seckill.currentId
              }
            })
            .then(limitedTimeSaleActivityId => {
              this.limitedTimeSaleActivityId = limitedTimeSaleActivityId
              this.activeIndex = 1
            })
            .catch(err => {
              this.$message.error(err.msg || '操作失败')
            })
            .finally(_ => {
              this.loading = false
            })
        }
      })
    },
    handleSave(row) {
      let i = this.currentEditId.findIndex(d => +d === row.productId)
      this.currentEditId.splice(i, 1)
      let { skuData } = row
      if (skuData.secKillStock > skuData.currentStock) {
        this.$message.error('秒杀数量不能大于剩余库存')
        return
      }
      if (!skuData.secKillPrice) {
        this.$message.error('秒杀价不能为0！')
        return
      }
      // if (!row.secKillStock) {
      //   this.$message.error('秒杀数量不能为0！')
      //   return
      // }
      if (!skuData.buyLimit) {
        this.$message.error('每人限购不能为0！')
        return
      }
      if (+row.distributionStatus === 1) {
        if (!skuData.secKillDistributionRewardAmount) {
          this.$message.error('分销金不能为0！')
          return
        }
        if (skuData.secKillDistributionRewardAmount > skuData.secKillPrice) {
          this.$message.error('分销金不能大于秒杀价！')
          return
        }
      }
      row.limitedTimeSaleActivityId = this.limitedTimeSaleActivityId
      let skuDataFilter = this.tableData.filter(c => +c.productId === +row.productId)
      let result = this.handleData(skuDataFilter)
      this.loading = true
      this.$api.seckill
        .seckill('saveSingleSecKillRelation', result[0], {
          headers: {
            ['Bm-Area-Id']: this.$store.state.seckill.currentId
          }
        })
        .then(id => {
          skuDataFilter.forEach((c, index) => {
            this.$set(skuDataFilter[index], 'id', id)
          })
          this.$message.success('操作成功')
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(_ => {
          this.loading = false
        })
    },
    save() {
      let result = []
      let currentData = this.handleData(this.tableData) // 现有的table数据
      let editData = this.handleData(this.originEditData) //回显的数据
      let delItems = []
      editData.forEach(c => {
        if (!currentData.find(s => s.productId === c.productId)) {
          c.deleteStatus = 1
          delItems.push(c)
        }
      })
      result = [...currentData, ...delItems]
      this.$refs.form.validate(valid => {
        if (valid) {
          if (!this.tableData.length) {
            this.$message.error('请选择商品')
            return
          }
          let isValid = true
          for (let i = 0; i < result.length; i++) {
            let item = result[i].productSkuLimitedSecKillRelationDBOS
            // 新增进行数据校验，编辑让接口校验
            if (this.activityId === 0 && item.some(c => c.secKillStock > c.currentStock)) {
              this.$message.error('秒杀数量不能大于剩余库存')
              isValid = false
              break
            }
            if (item.some(c => !c.secKillPrice)) {
              this.$message.error('秒杀价不能为0！')
              isValid = false
              break
            }
            if (item.some(c => !c.secKillStock)) {
              this.$message.error('秒杀数量不能为0！')
              isValid = false
              break
            }
            if (item.some(c => !c.buyLimit)) {
              this.$message.error('每人限购不能为0！')
              isValid = false
              break
            }
            if (+result[i].distributionStatus === 1) {
              if (item.some(c => !c.secKillDistributionRewardAmount)) {
                this.$message.error('分销金不能为0！')
                isValid = false
                break
              }
              if (item.some(c => c.secKillDistributionRewardAmount > c.secKillPrice)) {
                this.$message.error('分销金不能大于秒杀价！')
                isValid = false
                break
              }
            }
          }
          if (!isValid) return
          this.loading = true
          let url = this.activityId === 0 ? 'addSaleActivity' : 'editSaleActivity'
          if (this.limitedTimeSaleActivityId) url = 'editSaleActivity'
          let params = Object.assign(
            {
              description: this.form.description,
              limitedTimeSaleProductCount: currentData.length,
              name: this.form.name,
              startTime: this.form.dateRange[0],
              endTime: this.form.dateRange[1]
            },
            this.activityId
              ? {
                  id: this.activityId
                }
              : {}
          )
          if (this.limitedTimeSaleActivityId) params.id = this.limitedTimeSaleActivityId
          return this.$api.seckill
            .seckill(url, params, {
              headers: {
                ['Bm-Area-Id']: this.$store.state.seckill.currentId
              }
            })
            .then(limitedTimeSaleActivityId => {
              // 添加的活动id来源于该接口，编辑的活动直接取活动Id
              if (this.activityId === 0) {
                // 确保只使用第一次创建的id
                if (this.limitedTimeSaleActivityId === 0) this.limitedTimeSaleActivityId = limitedTimeSaleActivityId
                result.forEach(c => {
                  c.limitedTimeSaleActivityId = this.limitedTimeSaleActivityId
                })
              }
              return this.$api.seckill
                .seckill('saveSecKillRelation', result, {
                  headers: {
                    ['Bm-Area-Id']: this.$store.state.seckill.currentId
                  }
                })
                .then(res => {
                  ;(this.isNormal = false), this.$router.push('/seckill/list')
                  this.$message.success('操作成功')
                })
            })
            .catch(err => {
              this.$message.error(err.msg || '操作失败')
            })
            .finally(_ => {
              this.loading = false
            })
        }
      })
    }
  },
  created() {
    if (this.activityId) {
      this.loadProductData()
      this.loadData()
    }
  }
}
</script>

<style lang="less" scoped>
.seckill_wrapper {
  padding: 20px;
}
.seckill_setting {
  width: 100%;
  .com-input {
    width: 220px;
  }
  .wrapper {
    .avatar {
      display: inline-block;
      vertical-align: middle;
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }
    .name {
      vertical-align: middle;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
