/*
 * @Author: Link
 * @Date: 2020-11-13 17:08:37
 * @LastEditTime: 2021-06-08 14:03:22
 */


const class2type = {}
let getProto = Object.getPrototypeOf
let hasOwn = class2type.hasOwnProperty
let fnToString = hasOwn.toString
let ObjectFunctionString = fnToString.call(Object)
const mapType = [
  'Boolean',
  'Number',
  'String',
  'Function',
  'Array',
  'Date',
  'RegExp',
  'Object',
  'Error',
  'Symbol',
  'BigInt'
]
mapType.forEach(function(name) {
  class2type['[object ' + name + ']'] = name.toLocaleLowerCase()
})

const toType = function toType(obj) {
  if (obj == null) {
    return obj + ''
  }
  return typeof obj === 'object' || typeof obj === 'function' ? class2type[toString.call(obj)] || 'object' : typeof obj
}
const isPlainObject = function isPlainObject(obj) {
  let proto, Ctor
  if (!obj || toString.call(obj) !== '[object Object]') {
    return false
  }
  proto = getProto(obj)
  if (!proto) return true
  Ctor = hasOwn.call(proto, 'constructor') && proto.constructor
  return typeof Ctor === 'function' && fnToString.call(Ctor) === ObjectFunctionString
}
const each = function each(obj, callback) {
  let length,
    i = 0
  if (Array.isArray(obj)) {
    length = obj.length
    for (; i < length; i++) {
      let result = callback.call(obj[i], i, obj[i])
      if (result === false) {
        break
      }
    }
  } else {
    let keys = Object.keys(obj)
    typeof Symbol !== 'undefined' ? (keys = keys.concat(Object.getOwnPropertySymbols(obj))) : null
    for (; i < keys.length; i++) {
      let key = keys[i]
      if (callback.call(obj[key], key, obj[key]) === false) {
        break
      }
    }
  }
  return obj
}
const shallowClone = function shallowClone(obj) {
  let type = toType(obj),
    Ctor = null
  if (obj == null) return obj
  Ctor = obj.constructor
  if (/(regexp|date)/i.test(type)) return new Ctor(obj)
  if (/(symbol|bigint)/i.test(type)) return Object(obj)
  if (/error/i.test(type)) return new Ctor(obj.message)
  if (/function/i.test(type)) {
    return function anonymous() {
      return obj.apply(this, arguments)
    }
  }

  if (isPlainObject(obj) || type === 'array') {
    let result = new Ctor()
    each(obj, function(key, value) {
      result[key] = value
    })
    return result
  }
  return obj
}
let deepClone = function deepClone(obj, cache) {
  let type = toType(obj),
    Ctor = null,
    result = null
  if (!isPlainObject(obj) && type !== 'array') return shallowClone(obj)
  cache = !Array.isArray(cache) ? [] : cache
  if (cache.indexOf(obj) >= 0) return obj
  cache.push(obj)

  Ctor = obj.constructor
  result = new Ctor()
  each(obj, function(key, value) {
    result[key] = deepClone(value, cache)
  })
  return result
}

const SPECIAL_CHARS_REGEXP = /([\:\-\_]+(.))/g
const MOZ_HACK_REGEXP = /^moz([A-Z])/
function camelCase(name) {
  return name
    .replace(SPECIAL_CHARS_REGEXP, function(_, separator, letter, offset) {
      return offset ? letter.toUpperCase() : letter
    })
    .replace(MOZ_HACK_REGEXP, 'Moz$1')
}
// getStyle
function getStyle(element, styleName) {
  if (!element || !styleName) return null
  styleName = camelCase(styleName)
  if (styleName === 'float') {
    styleName = 'cssFloat'
  }
  try {
    const computed = document.defaultView.getComputedStyle(element, '')
    return element.style[styleName] || computed ? computed[styleName] : null
  } catch (e) {
    return element.style[styleName]
  }
}

function throttle(func, wait) {
  if (typeof func !== 'function') throw new TypeError('func must be an function!')
  if (typeof wait === 'undefined') wait = 500
  let timer = null,
    previous = 0
  return function proxy(...params) {
    let self = this,
      now = new Date(),
      remaining = wait - (now - previous)
    if (remaining <= 0) {
      clearTimeout(timer)
      timer = null
      previous = now
      func.call(self, ...params)
    } else if (!timer) {
      timer = setTimeout(function() {
        clearTimeout(timer)
        timer = null
        previous = new Date()
        func.call(self, ...params)
      }, remaining)
    }
  }
}
export { shallowClone, deepClone, getStyle, throttle }
