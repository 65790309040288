<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="isShowProduct"
    custom-class="add_modal"
    title="选择商品"
    width="60%"
  >
    <div class="search">
      <!-- <div class="inline">
        <label class="searchText">商品类型</label>
        <el-select v-model="searchValue.saleType" placeholder="请选择商品类型">
          <el-option label="全部" value="0"> </el-option>
          <el-option label="限时特卖" value="4"> </el-option>
        </el-select>
      </div> -->
      <div class="inline">
        <label class="searchText">商品id</label>
        <el-input
          class="searchInput"
          placeholder="请输入商品id"
          v-model="searchValue.productId"
          clearable
          @keyup.enter.native="loadData()"
        />
      </div>
      <div class="inline">
        <label class="searchText">商品名称</label>
        <el-input
          class="searchInput"
          placeholder="请输入商品名称或编码"
          v-model="searchValue.productName"
          clearable
          @keyup.enter.native="loadData()"
        />
      </div>
      <div class="inline">
        <el-button type="info" @click="resetData()">重置</el-button>
        <el-button type="primary" @click="loadData()"> 搜索</el-button>
      </div>
    </div>
    <el-table
      :data="listData.tableData"
      style="width: 100%"
      height="300px"
      :span-method="mergeRow"
      ref="multipleTable"
      @select="handleCheckSelect"
      @select-all="handleCheckAll"
      v-loading="loading"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column align="center" label="商品图片">
        <template slot-scope="{ row }">
          <div class="wrapper">
            <img class="avatar" :src="row.productImageUrl | thumbSmall" />
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="productName" label="商品名称"> </el-table-column>
      <el-table-column align="center" prop="productId" label="商品编号"> </el-table-column>
      <el-table-column align="center" prop="storeCount" label="商家门店"> </el-table-column>
      <el-table-column align="center" label="sku名称">
        <template slot-scope="{ row }">
          <p>{{ row.skuData && row.skuData.productSkuName }}</p>
        </template>
      </el-table-column>
      <el-table-column align="center" label="价格(元)">
        <template slot-scope="{ row }">
          <p>{{ row.skuData && row.skuData.salePrice }}</p>
        </template>
      </el-table-column>
      <el-table-column align="center" label="剩余库存">
        <template slot-scope="{ row }">
          <p>{{ row.skuData && row.skuData.skuInventory }}</p>
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <el-pagination
        class="pagination"
        :current-page="listData.currentPage"
        :page-sizes="listData.pageSizeOpts"
        :page-size="listData.pageSize"
        :total="listData.pageTotal"
        :layout="listData.layoutConfig"
        @current-change="p => changePage(p)"
        @size-change="s => handlePageSize(s)"
      />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="toggleModal">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import createData, { loadData, changePage, handlePageSize, freezeData, resetData } from '@/plugin/page-helper'
import { deepClone } from '@/util/util.js'

export default {
  props: {
    activityId: {
      type: [Number, String],
      default: 0
    },
    selectedProductId: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      isShowProduct: false,
      searchValue: {
        productId: '',
        productName: ''
      },
      originData: [],
      listData: createData(),
      loading: false,
      currentIndexArr: [],
      currentIdArr: [], //选中的id
      selectionTemp: [] //存储当前全选的内容
    }
  },
  methods: {
    loadData,
    changePage,
    handlePageSize,
    freezeData,
    resetData,
    onLoadData(data) {
      return this.$api.seckill
        .seckill(
          'getSecKillNotRelation',
          {
            params: {
              limitedTimeSaleActivityId: this.activityId,
              ...this.searchValue,
              ...data,
              type: 1,
              checkCodeSource: 1,
              secKill: 1,
              reservation: 0
            },
            headers: {
              ['Bm-Area-Id']: this.$store.state.seckill.currentId
            }
          },
          0
        )
        .then(res => {
          this.originData = JSON.parse(JSON.stringify(res.records))
          let transData = []
          res.records.forEach(item => {
            let len = item.secKillProductSkuAddVOList.length
            item.secKillProductSkuAddVOList.forEach((c, index) => {
              transData.push({
                ...item,
                skuData: c,
                rowMergeInfo: index === 0 ? [len, 1] : [0, 0]
              })
            })
          })
          res.list = transData
          this.listData.data = res

          this.$nextTick(() => {
            this.listData.tableData.forEach(c => {
              if (this.selectedProductId.includes(c.productId)) {
                this.$refs.multipleTable.toggleRowSelection(c, true)
              }
            })
          })
        })
    },
    toggleModal() {
      this.isShowProduct = !this.isShowProduct
    },
    mergeRow({ row, column, rowIndex, columnIndex }) {
      let skuIndex = [0, 1, 2, 3, 4]
      if (skuIndex.includes(columnIndex)) {
        return row.rowMergeInfo
      } else {
        return [1, 1]
      }
    },
    handleToggle(row, index) {
      if (this.currentIdArr.includes(row.productId)) {
        this.handleCancel(row, index)
        this.$refs.multipleTable.toggleRowSelection(row, false)
      } else {
        this.handleSelect(row, index)
        this.$refs.multipleTable.toggleRowSelection(row, true)
      }
    },
    handleSelect(row, index) {
      if (!this.currentIdArr.includes(row.productId)) this.currentIdArr.push(row.productId)
      // if (!this.currentIndexArr.includes(index)) this.currentIndexArr.push(index)
      console.log(this.currentIdArr)
    },
    handleCancel(row, index) {
      if (this.currentIdArr.includes(row.productId))
        this.currentIdArr.splice(this.currentIdArr.indexOf(row.productId), 1)
      // if (this.currentIndexArr.includes(index)) this.currentIndexArr.splice(this.currentIndexArr.indexOf(index), 1)
      console.log(this.currentIdArr)
    },
    handleCheckSelect(selection, row) {
      console.log(selection, row)
      let isChecked = !this.currentIdArr.includes(row.productId)
      let index = 0
      for (let i = 0, len = this.listData.tableData.length; i < len; i++) {
        if (this.listData.tableData[i].productId === row.productId) {
          index = i
          break
        }
      }
      console.log(isChecked, index)
      if (isChecked) {
        this.handleSelect(row, index)
      } else {
        this.handleCancel(row, index)
      }
    },
    handleCheckAll(selection) {
      if (selection.length) {
        this.selectionTemp = deepClone(selection)
        selection.forEach((item, index) => {
          if (!this.currentIdArr.includes(item.productId)) this.handleSelect(item, index) //避免重复添加
        })
      } else {
        this.selectionTemp.forEach((item, index) => {
          if (this.currentIdArr.includes(item.productId)) this.handleCancel(item, index) //避免重复删除
        })
        this.selectionTemp = []
      }
    },
    confirm() {
      console.log('点击')
      console.log(this.currentIdArr)
      if (!this.currentIdArr.length) {
        this.$message.error('请选择商品!')
        this.isShowProduct = true
        return
      }
      let selectData = this.originData.filter(item => this.currentIdArr.includes(item.productId))
      console.log('选中数据')
      console.log(selectData)
      this.isShowProduct = false
      this.$emit('getData', selectData)
    }
  },
  watch: {
    selectedProductId(nV) {
      console.log('更新')
      console.log(nV)
      this.currentIdArr = JSON.parse(JSON.stringify(nV))
    }
  },
  created() {
    this.freezeData(this.searchValue)
  }
}
</script>

<style lang="less" scoped>
.add_modal {
  .search {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
    .inline {
      display: flex;
      align-items: center;
      margin-right: 10px;
      .searchText {
        flex-shrink: 0;
        margin-right: 10px;
      }
    }
  }
  .footer {
    text-align: center;
  }
  .wrapper {
    .avatar {
      display: inline-block;
      vertical-align: middle;
      width: 50px;
      height: 50px;
      margin-right: 10px;
      object-fit: contain;
    }
    .name {
      vertical-align: middle;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
.page {
  width: 100%;
}
</style>
