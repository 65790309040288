import Vue from 'vue'
import { deepClone } from '../util/util.js'
let loadingInstance = null
const TEMP_DATA = {}
const PAGE_CONFIG = {
  PAGE_START: 1,
  PAGE_SIZE_DEF: 10,
  PAGE_SIZE_OPTS: [10, 20, 50, 100]
}
const TIME_RANGES = [
  new Date(new Date().toLocaleDateString()) - 3600 * 1000 * 24 * 7,
  new Date(new Date().toLocaleDateString()).getTime() + 3600 * 1000 * 24 - 1
]
let Data = {}
const createData = () => {
  Data = new Vue({
    data: {
      currentPage: PAGE_CONFIG.PAGE_START,
      pageSize: PAGE_CONFIG.PAGE_SIZE_DEF,
      pageSizeOpts: PAGE_CONFIG.PAGE_SIZE_OPTS,
      data: {},
      layoutConfig: 'total, sizes, prev, pager, next, jumper',
      timeRanges: TIME_RANGES,
      loading: false
    },
    computed: {
      pageTotal() {
        return (this.data && Number(this.data.total)) || 0
      },
      tableData() {
        return (this.data && this.data.list) || []
      }
    }
  })
  return Data
}

function createParams(page) {
  let data = {
    page: page,
    size: Data.pageSize
  }
  return data
}

function handleUpdate() {
  loadData.call(this, Data.currentPage)
}

function createLoading() {
  return this.$loading({
    lock: true,
    text: '数据加载中~',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  })
}

export function loadData(page = PAGE_CONFIG.PAGE_START) {
  // loadingInstance = createLoading.call(this)
  this.loading = true
  Data.currentPage = page
  let stime, etime
  if (Data.timeRanges) {
    stime = Data.timeRanges[0]
    etime = Data.timeRanges[1]
  } else {
    stime = etime = ''
  }
  return (
    this.onLoadData &&
    this.onLoadData(createParams(page), stime, etime).then(() => {
      // loadingInstance.close()
      this.loading = false
    })
  )
}

export function changePage(page) {
  Data.page = page
  loadData.call(this, page)
}

export function handlePageSize(pageSize) {
  Data.pageSize = pageSize
  loadData.call(this)
}

export function delItem(row, title, content, ...args) {
  if (this.onDelItem) {
    this.$confirm(content, title, {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      center: true
    })
      .then(() => {
        // loadingInstance = createLoading.call(this)
        this.loading = true
        this.onDelItem(row, args).then(bool => {
          if (bool) {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            handleUpdate.call(this)
          }
          // loadingInstance.close()
          this.loading = false
        })
      })
      .catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
  }
}

export function freezeData(data) {
  TEMP_DATA.initialValue = deepClone(data)
}

export function resetData() {
  Data.currentPage = PAGE_CONFIG.PAGE_START
  Data.pageSize = PAGE_CONFIG.PAGE_SIZE_DEF
  Data.pageSizeOpts = PAGE_CONFIG.PAGE_SIZE_OPTS
  Data.timeRanges = TIME_RANGES
  this.searchValue = deepClone(TEMP_DATA.initialValue)
  loadData.call(this)
}

export default createData
